import React, { useEffect } from 'react';
import '../../common/styles/fonts/noto sans/NotoSans-Regular.ttf';
import './App.scss';
import '../../common/styles/typography.scss';
import '../../common/styles/variables.scss';
import '../../common/styles/common.scss';
import { useIsAuthenticated } from '@azure/msal-react';

import { ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import theme from '../../common/styles/muiTheme';
import Header from '../Header/Header';
import ErrorPopUp from '../ErrorPopUp/ErrorPopUp';
import {
  ADE_ONLINE_UPDATES_FEATURE_FLAG,
  BACKUP_AND_RESTORE,
  BACKUP_VERSION_FF,
  DIAGNOSTIC_PAGE,
  VECTOR_EVENTS_PAGE,
} from '../../common/constants';
import AppSidebar from '../../common/components/AppSidebar/AppSidebar';
import TopLevelRouter from './TopLevelRouter';
import { RootState, useAppSelector } from '../../reducers/rootReducer';
import useInvalidateAppCache from './hooks/useInvalidateAppCache';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import useDisableGlobalPinchZoom from './hooks/useDisableGlobalPinchZoom';
import useRedirectListener from './hooks/useRedirectListener';
import useFeatureFlag from './hooks/useFeatureFlag';
import useAzureADActiveAccountLogin from '../../common/customHooks/useAzureADActiveAccountLogin';
import { selectInterceptorsStatus } from '../../reducers/interceptorsSlice';
import Preloader from '../Preloader/Preloader';
import useTabVisibility from '../../common/customHooks/useTabVisibility';
import useMopinion from '../../common/customHooks/useMopinion';
import { IS_CURRENT_BROWSER_FIREFOX } from '../Vector/mfrchart/constants';
import useErrorHandling from '../../common/customHooks/useErrorHandling';
import { selectAreAllFeatureFlagsFetched } from '../../reducers/featureFlagSlice';
import { getVectorConfiguration } from '../../reducers/vectorConfigurationSlice';
import ErrorBoundary from '../../common/components/ErrorBoundary';

const featureFlags = [
  ADE_ONLINE_UPDATES_FEATURE_FLAG,
  DIAGNOSTIC_PAGE,
  BACKUP_AND_RESTORE,
  BACKUP_VERSION_FF,
  VECTOR_EVENTS_PAGE,
];

function App() {
  const isAuthenticated = useIsAuthenticated();
  const isLoggedIn = useSelector(
    (state: RootState) => state.userSession.isLoggedIn,
  );
  const interceptorsStatus = useAppSelector(selectInterceptorsStatus);
  const areAllFeatureFlagsFetched = useAppSelector((state: RootState) =>
    selectAreAllFeatureFlagsFetched(state, featureFlags),
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (IS_CURRENT_BROWSER_FIREFOX) {
      const bodyElements = document.getElementsByTagName('body')[0];
      bodyElements.classList.add('mozilla-scrollbar');
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && interceptorsStatus === 'initialized') {
      dispatch(getVectorConfiguration());
    }
  }, [isLoggedIn, interceptorsStatus]);

  useAzureADActiveAccountLogin();
  useInvalidateAppCache();
  useGoogleAnalytics();
  useDisableGlobalPinchZoom();
  useRedirectListener();
  useFeatureFlag(featureFlags, interceptorsStatus);
  useTabVisibility();
  useMopinion();
  useErrorHandling();

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        {interceptorsStatus === 'not-initialized' ||
        !areAllFeatureFlagsFetched ? (
          <Preloader />
        ) : (
          <>
            <Header />
            {((isAuthenticated && isLoggedIn) || isLoggedIn) && <AppSidebar />}
            <TopLevelRouter />
            <ErrorPopUp />
          </>
        )}
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
