import { useCallback, useEffect, useMemo, useState } from 'react';
import type {
  IGenericTable,
  LocalStorageAcceptableKeys,
  Nullable,
  TableTypes,
} from '../types';
import {
  getSearchParams,
  senseCustomSorting,
  updateSearchParams,
} from '../utils';

const useSortingObject = <T extends Record<string, any>>(
  sortableItems: T[],
  localStorageKey: Nullable<LocalStorageAcceptableKeys>, // if localStorageKey is null, does not keep the params in the url as well
  defaultSortingKey: keyof T | 'none' = 'none',
  tableConfig: TableTypes.TableRow[] = [],
  customSortableKeys?: Nullable<Array<keyof T>>,
  handleCustomKeysSorting?: (
    sortingObject: TableTypes.SortingObject,
    valueA: T,
    valueB: T,
  ) => number,
): [T[], (item: TableTypes.TableRow) => void, TableTypes.SortingObject] => {
  const [sortingObject, setSortingObject] = useState<TableTypes.SortingObject>({
    key: defaultSortingKey as string,
    direction: 'asc',
  });

  const handleColumnClick = useCallback(
    (item: TableTypes.TableRow) => {
      let sortingObjectValue;
      if (sortingObject.key === item.key) {
        sortingObjectValue = {
          key: item.key,
          direction: sortingObject.direction === 'asc' ? 'desc' : 'asc',
        };
      } else {
        sortingObjectValue = { key: item.key, direction: 'asc' };
      }
      setSortingObject(sortingObjectValue);
      if (localStorageKey) {
        updateSearchParams('sort', sortingObjectValue.key, true);
        updateSearchParams('direction', sortingObjectValue.direction, true);
        localStorage.updateObjectProperty(
          localStorageKey,
          'sortingObject',
          sortingObjectValue,
        );
      }
    },
    [sortingObject],
  );

  useEffect(() => {
    if (tableConfig.length === 0) return;
    let newSortingObject: TableTypes.SortingObject | undefined;
    const sortKey = getSearchParams('sort');
    let direction = getSearchParams('direction');
    let localStorageValue;

    if (localStorageKey) {
      localStorageValue = localStorage?.getObject<
        IGenericTable,
        typeof localStorageKey
      >(localStorageKey)?.sortingObject;
    }

    if (localStorageValue) {
      newSortingObject = localStorageValue;
    } else if (sortKey && tableConfig.some((x) => x.key === sortKey)) {
      //  invalidate fake url params
      direction =
        direction === 'asc' || direction === 'desc' ? direction : 'asc';
      newSortingObject = {
        key: sortKey,
        direction: direction as TableTypes.SortingObject['direction'],
      };
    }

    if (
      (sortingObject.key !== newSortingObject?.key ||
        sortingObject.direction !== newSortingObject?.direction) &&
      newSortingObject
    ) {
      setSortingObject(newSortingObject);
      if (!localStorageValue && localStorageKey) {
        localStorage.updateObjectProperty(
          localStorageKey,
          'sortingObject',
          newSortingObject,
        );
      }
    }
    if (!newSortingObject && localStorageKey) {
      newSortingObject = {
        key: sortKey || (defaultSortingKey as string),
        direction:
          direction != null
            ? (direction as TableTypes.SortingObject['direction'])
            : 'asc',
      };
    }

    updateSearchParams('sort', newSortingObject?.key, true);
    updateSearchParams('direction', newSortingObject?.direction, true);
  }, [tableConfig, sortingObject]);

  const sortedValues = useMemo(() => {
    if (!sortableItems) return [];
    return [...sortableItems].sort((a, b) => {
      if (sortingObject.key === 'none') {
        return 0;
      }
      const isDate =
        sortingObject.key.toLowerCase().includes('time') &&
        !sortingObject.key.toLowerCase().includes('freetime');

      // apply custom sorting for custom keys
      if (
        customSortableKeys &&
        handleCustomKeysSorting &&
        customSortableKeys.includes(sortingObject.key)
      ) {
        return handleCustomKeysSorting(sortingObject, a, b);
      }

      let valueA = a[sortingObject.key];
      let valueB = b[sortingObject.key];

      if (valueA && valueB && isDate) {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
        if (sortingObject.direction === 'asc') {
          return valueB - valueA;
        }
        return valueA - valueB;
      }

      if (valueA === valueB) {
        return 0;
      }

      if (sortingObject.direction) {
        return senseCustomSorting(valueA, valueB, sortingObject.direction);
      }

      return 0;
    });
  }, [sortableItems, sortingObject]);

  return [sortedValues, handleColumnClick, sortingObject];
};

export default useSortingObject;
