import { PayloadAction } from '@reduxjs/toolkit';
import { put, call, select } from 'redux-saga/effects';
import {
  getEventDetailsError,
  setExtendedDates,
  setVdeEvents,
} from '../../../reducers/eventDetailsSlice';

import {
  setStartLoading,
  setEndLoading,
} from '../../../common/redux/loadManager/actions';
import { getLoadId } from '../../../common/redux/loadManager/methods';
import { api } from '../../../api/lelyBackend/dataChartsApi';
import { AwaitedApiResultType } from '../../../common/types';

let currentLoadId = 0;
export function* getVdeEventsSaga(
  action: PayloadAction<{
    entityId: string;
    startDate: string;
    endDate: string;
  }>,
) {
  currentLoadId = getLoadId();
  yield put(setStartLoading(currentLoadId));
  const { entityId, startDate, endDate } = action.payload;

  yield call(getEventDetails, entityId, startDate, endDate);
}

export function* getEventDetails(
  entityId: string,
  startDate: string,
  endDate: string,
) {
  try {
    const eventsData: AwaitedApiResultType<typeof api.getVectorEvents> =
      yield call(api.getVectorEvents, entityId, startDate, endDate);
    const { events, settings } = eventsData.data;
    yield put(setExtendedDates({ start: startDate, end: endDate }));
    const vectorConfigurationData = yield select(
      (state) => state.vectorConfiguration.vectorConfigurationData,
    );
    yield put(
      setVdeEvents({
        events,
        vectorConfigurationData: { ...vectorConfigurationData, ...settings },
      }),
    );
    yield put(setEndLoading(currentLoadId));
  } catch (error: any) {
    yield put(getEventDetailsError(error));
    yield put(setEndLoading(currentLoadId));
  }
}
